




























































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import {
  CHART_CLICK_EVENT,
  TASQ_PROLONGED_ANOMALY_TYPE,
  TASQ_STATE_CHANGE_TYPE,
} from '@/lib/constants';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import { Debounce } from 'vue-debounce-decorator';

@Component({
  components: {
    LineChart: () => import('@/lib/charts/lineChart'),
    VueSlider,
  },
})
export default class TasqSignal extends Vue {
  @Prop({ required: true }) signalData!: {
    id: number,
    name: string,
    unit?: string,
    dataset: any[]
    time: string[]
    annotations?: [],
  }

  yAxisRange =[1, 80];

  mouseLeaveRange() {
    setTimeout(() => {
      this.fixedAxis = true;
    }, 1000);
  }

  mouseOverRange() {
    setTimeout(() => {
      this.fixedAxis = false;
    }, 300);
  }

  longPress() {
    this.fixedAxis = true;
  }

   xAxisRange =['', ''];

   fixedAxis = false;

   minYaxis: any = 1;

   zoomUpdated = 0;

   maxYaxis: any = 100;

  showChart = false;

  chartLoaded = false;

  signalVisible = true;

  get chartFromTime() {
    // console.log(tasqsListModule.chartFromTime);
    return tasqsListModule.chartFromTime;
  }

  get chartToTime() {
    // console.log(tasqsListModule.chartToTime)
    return tasqsListModule.chartToTime;
  }

  highlightChart = false;

  get minDataValue() {
    return Math.max(...this.signalData.dataset);
  }

  formatXaxis(val) {
    return new Date(val).toLocaleString();
  }

  maxDataValue: any = 0;

  chartData: any = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      borderColor: '#2CE6C2',
      borderWidth: 1.5,
      pointBackgroundColor: '#0076FF',
      pointBorderColor: '#0076FF',
      pointRadius: 0,
      backgroundColor: 'rgba(44,230,194,0.1)',
      fill: true,
      showLine: true,
      tension: 0,
      spanGaps: true,
      pointHitRadius: 5,
      pointHoverRadius: 0,
    }],
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['mousemove', 'touchstart', 'touchmove', 'click'],
    legend: {
      display: false,
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    animation: {
      duration: 0,
    },
    hover: {
      mode: 'x',
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    tooltips: {
      enabled: true,
      displayColors: false,
      backgroundColor: '#fff',
      titleFontColor: '#8C8FB0',
      bodyFontColor: '#000750',
      bodyFontStyle: 'bold',
      titleAlign: 'center',
      bodyAlign: 'center',
      xPadding: 5,
      yPadding: 5,
      callbacks: {
        title: (tooltipItems) => `${new Date(tooltipItems[0].xLabel).toLocaleString('en-us', {
          month: '2-digit',
          day: '2-digit',
        })} ${new Date(tooltipItems[0].xLabel).toLocaleTimeString('en-us', {
          hour: '2-digit', minute: '2-digit',
        })}`,

        label: ((tooltipItems) => {
          const label = tooltipItems.value || '';
          if (label) {
            return `${parseFloat(label).toFixed(1)}`;
          }
          return `${label}`;
        }),
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          minRotation: 28,
          autoSkipPadding: 5,

          maxTicksLimit: 20,
          ...(this.chartFromTime) && { min: this.chartFromTime },
          max: new Date(),
          suggestedMax: new Date(),
        },
        time: {
          unit: 'day',
          displayFormats: {
            hour: 'h:mm A',
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [{
        gridLines: {
          color: 'rgb(113, 122, 139)',
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          maxTicksLimit: 4,
          fontStyle: 'bold',
          autoSkipPadding: 5,

          minRotation: 0,
          maxRotation: 0,
          precision: 2,
          callback: (value) => (`${value}`.toLowerCase().includes('e') ? Number(value).toPrecision(2) : Number(value).toFixed(0)),
        },
      }],
    },
    onClick: (evt) => {
      const chart = (this.$refs.chart as any).$data._chart;
      const item = chart.getElementAtEvent(evt);
      if (item[0]) {
        const date = this.chartData.datasets[0].data[item[0]._index].x;
        if (date != null) {
          this.$eventBus.$emit(CHART_CLICK_EVENT, date.toISOString());
        }
      }
    },
    annotation: {
      annotations: [] as any,
    },
  }

  get activeTasq(): any {
    return tasqsListModule.activeTasq;
  }

  created() {
    //  this.$eventBus.$off('x-axis-drag-end');
    // console.log(this.signalData.dataset.map(s => Number.parseFloat(s)));

    if (this.signalData.dataset && this.signalData.dataset.length) {
      const min = Math.min(...this.signalData.dataset);
      const max = Math.max(...this.signalData.dataset) + (Math.max(...this.signalData.dataset) / 100 * 5);
      Object.assign(this.chartOptions.scales.yAxes[0].ticks, {
        min: Math.min(...this.signalData.dataset),
        max: Math.max(...this.signalData.dataset) + (Math.max(...this.signalData.dataset) / 100 * 5),
        stepSize: ((max - min) / 2).toFixed(0),
        maxTicksLimit: min > 900 ? 3 : 4,
      });

      // eslint-disable-next-line radix

      if (Number.isFinite(Number((min).toFixed(0))) && Number.isFinite(Number(max.toFixed(0)))) {
        this.minYaxis = Number((min).toFixed(0));

        // eslint-disable-next-line radix
        this.maxYaxis = Number(max.toFixed(0)) < 0 ? 1 : Number(max.toFixed(0));
        const range = [this.minYaxis, this.maxYaxis].sort();
        this.yAxisRange = range;
      }

      if (this.signalData.time && this.signalData.time.length) {
        this.xAxisRange = [(this.chartFromTime), this.signalData.time[this.signalData.time.length - 1]];
      } else {
        this.xAxisRange = [(this.chartFromTime), new Date().toDateString()];
      }
    }

    // console.log(this.chartOptions.scales.yAxes[0]);
    this.showChart = true;
    this.chartData.datasets[0].data = this.signalData.dataset;
    this.chartData.labels = this.signalData.time;
    if (this.activeTasq?.predictionType === TASQ_STATE_CHANGE_TYPE
      || this.activeTasq?.predictionType === TASQ_PROLONGED_ANOMALY_TYPE
    ) {
      Object.assign(this.chartOptions, {
        annotation: {
          annotations: [{
            type: 'box',
            xScaleID: 'x-axis-0',
            borderColor: 'rgba(255, 95, 51, .6)',
            backgroundColor: 'rgba(255, 95, 51, .6)',
            xMin: this.activeTasq?.stateChangeStart ? `${this.activeTasq?.stateChangeStart}Z` : '',
            xMax: this.activeTasq?.stateChangeEnd ? `${this.activeTasq?.stateChangeEnd}Z` : '',
          }],
        },
      });
    } else if (this.activeTasq?.predictionType === TASQ_PROLONGED_ANOMALY_TYPE) {
      Object.assign(this.chartOptions, {
        annotation: {
          annotations: [{
            type: 'box',
            xScaleID: 'x-axis-0',
            borderColor: 'rgba(255, 95, 51, .6)',
            backgroundColor: 'rgba(255, 95, 51, .6)',
            xMin: this.activeTasq?.stateChangeStart ? `${this.activeTasq?.stateChangeStart}Z` : '',
            xMax: '',
          }],
        },
      });
    } else if (this.activeTasq?.overriddenName === 'Real-Time') {
      Object.assign(this.chartOptions, {
        annotation: {
          annotations: [{
            type: 'box',
            xScaleID: 'x-axis-0',
            borderColor: 'rgba(255, 95, 51, .6)',
            backgroundColor: (this.chartData.labels.length && this.activeTasq.outcome) && this.activeTasq?.outcome?.replace(/\s/g, '') === 'liquidloading' ? 'rgba(255, 204, 51, .6)' : 'rgba(255, 95, 51, .6)',
            xMin: this.activeTasq.rtStartDate || '',
            xMax: '',
          }],
        },
      });
    }
    this.chartLoaded = true;

    this.$eventBus.$on('x-axis-drag-end', (xAxisRange) => {
      const dateHours = xAxisRange.map((s) => s.split('T'));

      // console.log(dateHours);

      // console.log(this.chartData.labels);
      // console.log( this.signalData.time);

      const start = this.signalData.time.find((l) => l.includes(dateHours[0][0]));
      const end = this.signalData.time.find((l) => l.includes(dateHours[1][0]));

      if (end && start) {
        this.xAxisRange = [end, start];
        Object.assign(this.chartOptions.scales.xAxes[0].ticks, {
          min: start,
          max: end,
          maxTicksLimit: 20,

        });
      }

      this.zoomUpdated += 1;
    });
  }

  beforeDestroy() {
    this.$eventBus.$off('x-axis-drag-end');
  }

@Debounce(1500)
  xAxisDragEnd() {
    this.$eventBus.$emit('x-axis-drag-end', this.xAxisRange);
  }

  @Debounce(100)
  @Watch('yAxisRange')
updateMaxRangeValue(value) {
  // this.signalVisible = true;
  this.zoomUpdated += 1;
  Object.assign(this.chartOptions.scales.yAxes[0].ticks, {
    max: value[1],
    min: value[0],
    stepSize: ((value[1] - value[0]) / 3).toFixed(0),
    maxTicksLimit: 3,
  });
}

  @Debounce(100)
  @Watch('xAxisRange')
  updatexAxisRangeValue(value) {
    this.zoomUpdated += 1;
    // this.signalVisible = true;

    Object.assign(this.chartOptions.scales.xAxes[0].ticks, {
      max: value[1],
      min: value[0],
    });

    // @ts-ignore
  }
}
